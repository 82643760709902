import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { CardWithImageLink, CardContainer, CardBody } from '../../../components/Card';
import { ButtonOutbound, ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import { OutboundLink } from '../../../components/StyledLink';
import { CallToAction } from '../../../components/CallToAction';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Box, ProjectBox } from '../../../components/Box';
import { Text } from '../../../components/Text';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdHammer from 'react-ionicons/lib/MdHammer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Slack Themes" mdxType="SEO" />
    <IntroProject projectName="Slack Themes" projectDescription="Beautiful, curated themes to use in your Slack workspace" projectYear="2020" projectImage="slack-themes-preview" mdxType="IntroProject" />
    <Section section="Slack Themes" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <ProjectBox image="slack-themes-example-icon" title="Slack Themes" description="Choose and copy a theme to personalize a Slack workspace." link="https://slack-themes.now.sh" mdxType="ProjectBox" />
          <h5>About the project</h5>
          <IconCard cardTitle="Specifications" cardText="Help users personalize their Slack workspaces by browsing through an easy-to-use interface" mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Tools" cardText="Notion, Figma, Firebase, React, Styled Components, Google Analytics, Google Forms" mdxType="IconCard">
            <MdHammer fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdHammer" />
          </IconCard>
          <p>
            <OutboundLink href="https://notes.ryanparag.com/notes/building-an-app-for-slack-themes" target="_blank" mdxType="OutboundLink">I wrote about how I built Slack Themes!</OutboundLink>
          </p>
          <p>Slack is a communication and chat tool that helps teams work better together. Inside the app, users are able to configure the theme of their sidebar by choosing from the handful of themes default Slack themes.</p>
          <p>Since I'm somewhat of a Slack workspace <strike>connoisseur</strike> hoarder, I found I needed to differentiate my Slack workspaces from each other and that using a different them could help with that issue.</p>
          <p><strong>But, in order to change a theme, I had to go through multiple steps: open the menu, click preferences, change theme.</strong></p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "87.08333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACz0lEQVQ4y41UaU8UQRCdH8AXDbBzHz33zrEze4GBWVgXRE3UEIKSGCVETUATYzDyzegv72d1z+4KeH54qe3p7veq6lWv4ngh/KiLgOCyGIbFCB5005XxP8FNm4k7XLEsB2EYIfBDGLoBTdNh0iGXRUtScVjgOsmtNW+TcLiSDKcYzY4QjfZhFRPYeQMn6CLLe7DdQF5QNQcdzZYCApoh4EjMv0lCVbe5ks1eYePoEvnj94gPLhDcfwM3rjEYjpEVtbyclTnq4QCW0wpESSb3/DBdVMEFeRClXGHMR5GXhIKQw7ZsOuDCYyERVRRzPH1Z4MlJH2FUEUmClZW7COMMRW8A2/ElIWUnRLgiVLKyj5xgk0G66clSe70+ulS2MKnZmWI624fnx3KvmQ0xvreJJC2pl96SsFtUlGGQSKVuXlFJftsTOqS6TH4TpVx+vsLFh4/SKI8lOP1UYWO7oowLSiJYEibdgitibNqRiWR/OkSQ+wnOmj1Uw02551BWeVnPRRlen77DdjMFC+JFEtIUEueKyEjVW8fEuKxR7Pspvox3wahPgtAmEp8up1nr/Ndv37HV7MokxPoG4e0hFQImqbKslD1MuqXMTMQ4LSSp50eiPHI7X8wjF72Wc/g7QlFGRiT53KxiHstqKNH+HkgIt8kHLoT+nCGpyoM0ATIDgmzJ/MUssBjsxUv5lVA4PI+GZsEkaB0DumogpB6uqhbu0LpD+2v0cnwSFaYKouum8AWEUht9ntUTnva2ueH1uBuPeDHc4cWg4ZMHhzwbTDjLxvJ3nNVc1ay/meLA8FLUB6fYOTxHsHUMf/MYcfUQG7NDvHh7hUfPzzHaO0Hz7AxhPoCmW/9wmQa7rsmIrIBjU/lUsqtb5HCBuj+S+6pmwqLhNx22/FeShGsdnd/AusbXVYO7LOTkNj16m9YmX6U9GmrueIHc7xDWOwI/766uq/wHnzvo0nzlzNAAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Slack Themes",
          "title": "Slack Themes",
          "src": "/static/e94cf68167594dd715821b657c716d89/d9199/before.png",
          "srcSet": ["/static/e94cf68167594dd715821b657c716d89/8ff5a/before.png 240w", "/static/e94cf68167594dd715821b657c716d89/e85cb/before.png 480w", "/static/e94cf68167594dd715821b657c716d89/d9199/before.png 960w", "/static/e94cf68167594dd715821b657c716d89/07a9c/before.png 1440w", "/static/e94cf68167594dd715821b657c716d89/1acf3/before.png 1596w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}>How can we make the process of selecting themes simpler?</p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}>What if a user wanted to do this in less steps?</p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}>What if a user wanted access to more theme options?</p>
          </Box>
          <Box marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}>What if users could access and query themes directly from typing a Slack command?</p>
          </Box>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <Section section="Goals" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>🎯 Goal Setting</h5>
          <p>The idea of building a simple app was tempting - I could not only help alleviate some issues I encountered, in a fun way, but I could also use this experience to learn how to design/build using a few newer technologies (automatic deployments, CSS-in-JS, Firebase).</p>
          <p><strong>So my goal was to design & build a simple, free, and straightforward experience for users to find and implement a curated Slack theme, even if they are not Slack-savvy.</strong></p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">The Challenge:</Text>
            <p className="lead">Help Slack users differentiate and personalize their Slack workspaces through a simple, configurable interface</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Section section="Challenges" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <p>While working on personal projects, it's tempting to fall into <i>Blue Sky</i> ideas, where I could add unnecessary features, but would detract from the goal of keeping the product simple.</p>
          <p>So I  would regularly stop myself to say:</p>
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <p style={{
                  marginBottom: '0'
                }}><i>Which of all possible features are going to add real value to this product?</i></p>
            </CardBody>
          </CardContainer>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAADu0lEQVQ4y3WSbUxbVRjHrzCd8Zt+cGZRv+gw0fjFF2IWjMTEoYtkmvgyPyxOBjPCQnFbGC1TwtqyMDpe3BDpFsYG2QrLwE0DlIkBWrrSjeIuAwo1DoTb29sXblvWstLy/D29OLdEPckvT8499/zzf/7P4SzSBKyMVB2SeAx5/5thdn4P5b8HGJZ4+vuMuJTQr+JvGBenIciLEJYDEBmeiP8+bL8QEvGnLCh4whK8YT+ksI9VH7snkRAUISyJxDmCLjgjbsxFRCBJoOQaQPjXWolFEAiIiC4vISKH4LDaYR+ygnfexN07UZJFCRGfnzjjpVZsf3cbmlta4JWXIHo8CIXCWFtbQyKZwGoigWg0yr6FIEk+BINLCDJB242bsDjGwU+7EYrESPCGIPrCxJUe14JL34iv9x/A7bnb4Hket25NYmZmFk7nOObn5xWHmsNVyMrOxTvbd+JSZwe+LfoI+R9sxYkjRTB1/04FB6zYpx4hrryhChkvvIS6+gZ4PCJcrhmIohder6TsZVlmCRAKSzR4OXMb3sjKxXljE3R5WSh+fwsayz/BWRNPOwvMyFMNEKeu1WLzU8+gxlC7ntXKilKJSCHVegr79TH09P4Cs3kA01Mz+Kl3ABcv92LAYgM/6acr5gX0DAgpQR04jsOhMo0iFAgEsLy8jFgsppDKL5lMovtiJ6p1WtTVHIPdZsP3DQ04WlkJU3s7xm7Yqe3cKXSYzhJXxhxy3ENQl3/DBFYwNT2t5Le4KMDn8zN8WInHUb/rM+ze9Di+fPpJdFXX4KvXcrDjiQxoP8yDwXCcXn09E2++lc0E2VDS0jaiolKrOExNMTXlcERmTkMsgrtKy431dSjM34OSvfn4uasLVTo9NAdL0fRdI/p7+sig1ePEMcMDDjWHFcHV1VWlxSR7Msm1xD9ZxuJJhO/EEVslCGxgPxiNaDh5Ep0dneDZg77imUGv6L6f4T3B/1sxH4+41w7I45DmXag8oodKpUJLsxHjskDNbgdOz15XHBITpF2ff0H8xARdu2an0VGHgn10lGbdc7Sw6KdZl4ssg2ayWnvIYh+kNtN5am27QN0/9tHV0THqHhmG7Y8p4kr05YrDRx5+DOlpj2JDivR1OG4Dzp3RIRS0wOcZRO7HOXjx063I2vcenn37OWzKyMErO47i+WwNtmTm4jLfT9zp/g7avb+Q9qpLqOCQilG8XstUtKe0mC701ZF16gyNTLaQ/lQFlRq1VNFeTar6MirS6Und1Eoltc10sM5Ag4KT/gIBNJ+dlKcWmAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Slack Themes",
          "title": "Slack Themes",
          "src": "/static/a751353d3c370f6b6ee3279fabc1324e/d9199/preview-1.png",
          "srcSet": ["/static/a751353d3c370f6b6ee3279fabc1324e/8ff5a/preview-1.png 240w", "/static/a751353d3c370f6b6ee3279fabc1324e/e85cb/preview-1.png 480w", "/static/a751353d3c370f6b6ee3279fabc1324e/d9199/preview-1.png 960w", "/static/a751353d3c370f6b6ee3279fabc1324e/c1b63/preview-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="The solution" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Slack Themes Web App</h5>
          <p>A customizable, web-based interface that allows users to browse and preview themes and provide a way to configure themes to their preference.</p>
          <ul>
            <li>Browse hundreds of themes</li>
            <li>Toggle colors on each theme</li>
            <li>Access themes from a Slack command</li>
          </ul>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.583333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz01R0WoTURTcr5DiHygI/QSf/YQ++qQIPhQViiA1+AFFrZWSFrFgEW2poYkBC+K2JNkmaZKmuja0hEATk3t112SbTXZvMuNNosULw3AOZ4Yz9xg490GNCfcAT7MfoHFaRSFtoZwvoJTNobSfw2E2j7zu1ezK39nuhP/TG3Q98LeHoXCBX20MNKiIYsbC4bevlFLScR222216nseWFMyl0mCni6HTwVDPY+ShAV0baDrazIFqSAzqEnQ82JkSVqKvcdaoUwgJbYp+vw+SI3BrYxPlz6lxErp6AamXERMY4ckZKHTTC6Bazlj0LLqMm3duj7QIwhBhqDTCC9PI43nO3LiF7eUUCvEDqFoLrAug8VNHFh0cmxbmZu+h2qxR8pyvYu84Nx/hQCk4jgvf7yIIggvD5wuLfHj3KdeX0lyMbLJ6pP9UdoHqDxgbSyuYnprCzOVLjL1/Q7tyjPJuBomt7XG8fzFHTyk15mQsgWhkDS+ffMT66geelBfoCov9poCRW3uL+9PXcP3qFTyafQC/WEFQ+I4v8SSSn3aY11c2zV3E4wn0er2xedbcQ8cqwrVs7MdMfcAdnB68oGeb+ANhVOsO5UMAXAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Slack Themes",
          "title": "Slack Themes",
          "src": "/static/00aaef89abaca80cf5e6dae90b5c4bea/d9199/preview-3.png",
          "srcSet": ["/static/00aaef89abaca80cf5e6dae90b5c4bea/8ff5a/preview-3.png 240w", "/static/00aaef89abaca80cf5e6dae90b5c4bea/e85cb/preview-3.png 480w", "/static/00aaef89abaca80cf5e6dae90b5c4bea/d9199/preview-3.png 960w", "/static/00aaef89abaca80cf5e6dae90b5c4bea/07a9c/preview-3.png 1440w", "/static/00aaef89abaca80cf5e6dae90b5c4bea/29114/preview-3.png 1920w", "/static/00aaef89abaca80cf5e6dae90b5c4bea/8171b/preview-3.png 2671w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Design & Build" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>How was it built?</h5>
          <p>As a personal project, I thought it would be great to use this as an opporunity to learn how to build a simple product using some modern design/development tooling. Not only that, but I could build a process of user research and feedback from the start. This project was built using:</p>
          <ul>
            <li>Figma</li>
            <li>Google Forms</li>
            <li>Firebase</li>
            <li>React</li>
            <li>Styled Components</li>
          </ul>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAAD80lEQVQ4yzXUa1BUZRgHcD73pU99SNYAa7CAZdn7FRRRHEeHcCCmmLELlRJKXvKShTJ4QYeLI8lgGuZS0kxUBpHGZE40BgFqdpkQEmiBZXfZZZfDsrezl//Ts0udmf+c98v5zf993ndOElk6AA5NcyxmYOoKYpOXEX10EZG/WxEea0FotBmhvxoR/PMsAr+fhv+3E/D9Wovl+zXw3j2KpZHDEIbeweLgPiQlsJlPGL3KUDuiE5cYakN4/ALEh+chMhYebUQgjv1Rz9hJ+B7UMXYc3nvvM/YuhOFDjO2HZ6AaSWS7hrH+ZlTvLMeeN19E1etlnBdQ+Vop3qoowcsvFeE7817QWD1/fBTekSPwDh/E0tB+CINvY3FgNzx3KuH5eQ/cd3Yz6PgUP3bVoriknF6t2EVbtm0nhcpAKo2J9IZ1lCEzUeuJHTyOVgT/+RJhSxcCE53wjXdwzJyr8D1sh2fwABZ+2hlvaEb/F8ewafNWKi0tQ9Hz22Ew5iZiMuUiU6antrpSkPMWZmwCWa02CobCFH/AESMx8gej5H5wHq7bOxi0XkF/Vw1yFFpqaGxCY1MznsuQkk5vglarR3qGmi4cK06Ai8thmpqcgCAIcQtADOGwiEBQJNf9c3D+UM7g7GXc/uxIAtxQUIj8/I2Qy9WkVGmhUmuwZq2cPqgpQni2F52f95DHswBRFBHn4s9/Rcl1rwnz35cxOH0Rt64dhEaXR0bjOt5qHkM6ksmUDKuQ8oyMWt7bitB0Dzo6r9OCy4loNJpA/jdjzDvvNsDRV8IgD7vPvA8Fm9dTwab1MOWZYDDpSaPTQG/UID1LTs2HC0HzfQkkFo0gEomsdFtpl1g6R87CfrOYwakW3Py4Grn5BjLk6qBUq6DSqEipVnJTBdLSpdR0aCNitl443AItLrp4hh6I4SBFoiLjIUSiMZofroftxjYGJ8/hRnsVdCYt6Y3aOAalRsmgAgqVHJK0DGo4kA/M9cC15CZ/wIOQ6IM/KPBpe/lABARCAXIMncJc7xYGHzXg2492QSrTkIFnqNWZeK2kTKkc2TI5klOz6Mze3AToXgqTy+mEy+XiLJCT1x63m+EI7L/UwdpTyOD4GXxz6Q1Ic9QJUMOgWmvkpgbeshZPpmTS6WoDg90QliOJU45fG7/fD5/PhwC/g2KE7AO1mO0uYHDsFHo+rED6s9kky1GRVKaIt6PMrBySZufgieS1dLJKC1ivY27eSzPTFszN2cjhcCRit9tp2R8k28BxzH69gX8Oo3XobnsFqyRrkJL6NFY/lQaJJBWrklMgWZ2Cxx6XUF2lEjHrV5i1CzTJF9tiscBms4FBMAifP4QVsAD/AgZxXonxuaTDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Slack Theme toggles",
          "title": "Slack Theme toggles",
          "src": "/static/027ca691b8e02d42d6f18cb0b7865532/d9199/features-1.png",
          "srcSet": ["/static/027ca691b8e02d42d6f18cb0b7865532/8ff5a/features-1.png 240w", "/static/027ca691b8e02d42d6f18cb0b7865532/e85cb/features-1.png 480w", "/static/027ca691b8e02d42d6f18cb0b7865532/d9199/features-1.png 960w", "/static/027ca691b8e02d42d6f18cb0b7865532/c1b63/features-1.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Features" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Letting users customize</h5>
          <p>One of the goals of for this product was to retain simplicity - it needed to provide enough features to create customizable points for users, but not so many to bubble unnecessary points of friction</p>
          <p>One area where I saw a need for users to toggle was for Slack's top header color. Some users may find brightly colored headers intrusive and distracting. As such, I thought it could be useful for users to toggle this part of the theme On/Off; where if it was on, it would paint the header the same color as the sidebar background, and if off, would use the active color of the theme</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "44.583333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6klEQVQoz62Rv28ScRiHbzTGUhGSRmiheBTuQFBTTKGaaOJo4uykiYvGanRjpZCSFkEsg3sHTQq0SDf+hCY6thw/OpAGwo+rCYs16fD45Who/wAvefLkfd9PvsPnpD+np/w9O+N/fdLJr5+0trboFIu0C3naxYJwgeORd3bo/CjR2SvTKZXG8+7uOLO9fUE+z/H3b/ze30f6ms2ypPp4GAwSVlWeP33GouI38DtlvLNOlLl5YYHdicfmEL6EmH1zLtwzdj6+eYuU3dzE4/MTWgpzZ3GR++EIsurH6VZxyF5mXQsTXB4fSuAe8wvqZDfKuLw+rOLh1yvvkXRdp9locHR0RLPZoFGv02q16PX6dLu9c7qG+/0++kA3PLqPGAwG6PoJPXEfDodIlUqFaDRKIpFgdXWVeDzO2toaGxspNlIp0pkMn7NfSKczxpxKfWJ9fd3IJJPJiWOxGOXyHlJSHG32WWRZxuFw8OjxE2yit5uiH7NlhqlpC6brVoGFa6YblzCPmTIzbbZy5aqJFy9fIeVyOQKBAJFIhFAoxPLyA1R/EI9yG7fo7JZbQV5QDCu+AMG7Ibxq4HyvGoyyNvHTVt59GHeoaRoN0WNd9Fer1Yz5sFqlWtWoaheMbnWR04Qv7zWtxsHBIe12h3/vSM9Q11LPZQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Slack Theme toggles",
          "title": "Slack Theme toggles",
          "src": "/static/4e730a7b8c754880c5b1455a330881ba/d9199/neutral-nav.png",
          "srcSet": ["/static/4e730a7b8c754880c5b1455a330881ba/8ff5a/neutral-nav.png 240w", "/static/4e730a7b8c754880c5b1455a330881ba/e85cb/neutral-nav.png 480w", "/static/4e730a7b8c754880c5b1455a330881ba/d9199/neutral-nav.png 960w", "/static/4e730a7b8c754880c5b1455a330881ba/07a9c/neutral-nav.png 1440w", "/static/4e730a7b8c754880c5b1455a330881ba/29114/neutral-nav.png 1920w", "/static/4e730a7b8c754880c5b1455a330881ba/f37ba/neutral-nav.png 2216w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Features" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Designing for flexibilty</h5>
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <p style={{
                  marginBottom: '0'
                }}><i>What if a user wanted to know the theme name when sharing a theme in a Slack thread?</i></p>
            </CardBody>
          </CardContainer>
          <CardContainer mdxType="CardContainer">
            <CardBody mdxType="CardBody">
              <p style={{
                  marginBottom: '0'
                }}><i>What if a user wanted to paste a theme directly in their preferences?</i></p>
            </CardBody>
          </CardContainer>
          <p>Sometimes, a user may choose to share theme to others in a Slack channel. In those instances, where there could be many themes populating a screen, it could be helpful to differentiate them by label.</p>
          <p>Other times, a user may be pasting a theme directly in their preferences, where there is no need for a theme to be labeled.</p>
          <p>To help circumvent these inconveniences, users could toggle the addition of the theme label before copying a theme</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0klEQVQoz42SS2tTQRSAs1EUCiJI0JqmadKkad61VoRSFfwVCm6tDa4Vd4oLG3Wl+ChqW/wdxX9g3fkg7c2rkOSmJbnmPpJ7b/I5mT6ykKIDH+cwnPk4M2c8VqeNYWl0bYtu1xTxAJHbTgfXtXF7DtCjj8txq9/vy+ip725TVX9hWi1+6w3BLm2B1lbFniZFe3sNHjx8xOK9LHcXlw7IspS9z63bd9jY+CJlruvi6XZtdN2g3dZF1Ol0OkeYpimKHEqlEvFkhrHxEIFgBJ8/gG98konJMCdPj/D23YoUOo6Dp9lsks/nKRaL8mC9rqKq+9RqNVqtFt9//OTK1QUhvUQidZlYPCXyNOmZOc55L7K6tj7sUNM0thVFCEtUdnZQRK4oBQqFIvmtLcqVCl83vzE7N08kmiQUjokuw0yEIoSnYpw56+Xjp9Wh0LZtebVDLMs6wjBMWVQuV5hfuEEqM0siOUM0nmA6mhDdZvCe97G2/nko5D+W2mhw7fpNecVYIsPUdJyoEA7e9S/hYNzH0ev1ZGG1WhOitBhGkDF/iAs+P6OjfvyBICdOjfB+5cP+UP4lPPxbumHw6vUblnMvJM+Wn0tyuZc8fvJUvPGmrBs08AeaSTe6lXnH9AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Step-by-step process",
          "title": "Step-by-step process",
          "src": "/static/ad58d6bf70f51dab36a00f90193d0fd1/d9199/theme-label.png",
          "srcSet": ["/static/ad58d6bf70f51dab36a00f90193d0fd1/8ff5a/theme-label.png 240w", "/static/ad58d6bf70f51dab36a00f90193d0fd1/e85cb/theme-label.png 480w", "/static/ad58d6bf70f51dab36a00f90193d0fd1/d9199/theme-label.png 960w", "/static/ad58d6bf70f51dab36a00f90193d0fd1/07a9c/theme-label.png 1440w", "/static/ad58d6bf70f51dab36a00f90193d0fd1/29114/theme-label.png 1920w", "/static/ad58d6bf70f51dab36a00f90193d0fd1/37f8f/theme-label.png 3056w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "90.41666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABCcAAAQnAEmzTo0AAACbElEQVQ4y3VUCZKbMBDcb+aFqcoT8oHsbuxNXJXDNvhYDDYYYw4DEpc6M8gcXjtUqUZIrVb3zJSelEL7KZo03Rzdml4H/o9pRhgOTx2wg9WlgKokqkLQf9UTDZgapcwpFISVTIkxx5OOCmkBzA7A0hWYWgKLQ47prsTh0ulR8FLgzalheDl+2pIwWft/KTSmVdhdfM4VZq4+ND8Bxwz4fQTew8HyLlb45QEuYSZ7jZm5QJCPLHcKI3L4agNOovBjr7CnOHEUEQ4KrYgu8RS+msCnLzXMU0MYLeZOIRMaAW8C21DH9RlE3AvEIVGt4g0Rf57EePcSrAgb3ivUhM87wI4pT2THofjd1uRjyy87hWOqlbKbb9ZDQoWQCBe+avOxPutoBqq9oLPManktyPQeW+Uz7ObOciL5drJJit6o2huKr5QfKxqKwopebL03JRds/dlmdzd9OOqxpkaeZyikaGNVyLs+LGktzzJIkbexrspRH6rBckJn/bSBF0m41+HFBU75YDmguX+p4Sdlu+dSnty4RCw7yy2hvjkk2VWDuy+Ug0LOVV1XEEKgqqoeE8mbHOrbE+r2UxBiYxgIwxDhmYbvwz4OfeNfShi0v1wusd5sYZgmzNUGh3PW2+4JWXYUJbBXK+wtC/Z2C2ezxso6Xh8BjYnjBPO/cxjWGo67h/lngZ0XfyTUlRKypIMNxoWK5PDqUNqoKAUcx4HnuUgvCRqCx48scx/KivJY0WsyGlyojpB7k58rUZTIcqq2kO0ZPjsqis5PWuikt9a7IfToXj+ec/EYwznnwc3NZ3vLN314bY+P4/ahfYQZ9v8BmBx12aQlbu0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Step-by-step process",
          "title": "Step-by-step process",
          "src": "/static/ea5fac4a20cfc392b852e8f94a5a0859/d9199/process-1.png",
          "srcSet": ["/static/ea5fac4a20cfc392b852e8f94a5a0859/8ff5a/process-1.png 240w", "/static/ea5fac4a20cfc392b852e8f94a5a0859/e85cb/process-1.png 480w", "/static/ea5fac4a20cfc392b852e8f94a5a0859/d9199/process-1.png 960w", "/static/ea5fac4a20cfc392b852e8f94a5a0859/bed7a/process-1.png 1437w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Qualitative Feedback" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Feedback for the future</h5>
          <p>Response for the basic functionalities of the app seemed to be great. Seeing how users were interacting and copy/pasting themes helped drive new features onto the roadmap (like the upcoming Slack command 💯).</p>
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "24.166666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtUlEQVQY042Q7QqCMBSGvf/rqm6hNFCcbrrPDAzdlN7OZj8kiDrw8rAf59nLyRbZISb0IhGDw9MZ4GaJluiwGoWgegQtiXLjbmefLMnio+cItHw6HNG0LVhdgxPLssRjHPE5C30cOv5DSI3OlwJcCOR5jqK4JpZVBW0MtNaQUsI6an0fvguTtNuEcWKjtmlSGGNw1sJ7j3meMU8TfAj/NBSJE8XTnVarsZgYlRi0Slne3O/shS8XEXpVu67LTgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Step-by-step process",
          "title": "Step-by-step process",
          "src": "/static/21e7af2e4ce40193e023de5cdae49bda/d9199/feedback-1.png",
          "srcSet": ["/static/21e7af2e4ce40193e023de5cdae49bda/8ff5a/feedback-1.png 240w", "/static/21e7af2e4ce40193e023de5cdae49bda/e85cb/feedback-1.png 480w", "/static/21e7af2e4ce40193e023de5cdae49bda/d9199/feedback-1.png 960w", "/static/21e7af2e4ce40193e023de5cdae49bda/07a9c/feedback-1.png 1440w", "/static/21e7af2e4ce40193e023de5cdae49bda/29114/feedback-1.png 1920w", "/static/21e7af2e4ce40193e023de5cdae49bda/7f7f8/feedback-1.png 2396w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.750000000000004%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABDklEQVQY011RW26EMAzc+x+mX/3vIZB4hK1ggRAodF/Atgrg6SQUdVVLI4+VeDxxDnNrMDc1bP+Br76DDHdgGoFx2DKx3K7E5SkT162ePztY9jsdSxxcsXYtujzD2+sLtNZIlEKW51BpiuPxHcu6woX8Yg/Hl3GENfpPcHOo8c2JAyc2xkCpBFmWIQpDpBSt6xpN06AsSxieV1UFTZSVhr3fvKB9EhRrKlnGwRvgZQnDUJRSopJEgiDwdUIex7HnURT5epgmWQnX73UI79BbdjtjdH3vn1sUpUfFFZyKApouDV36Mzrtec+F6/v/ZOEehZ8i9noWfow8yB+dQ7vV5C7vfGwbz+3lvPWxf3f4A6/rwqqCD3a8AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Step-by-step process",
          "title": "Step-by-step process",
          "src": "/static/1a0030b498e7b52316d86972325d5d5d/d9199/feedback-2.png",
          "srcSet": ["/static/1a0030b498e7b52316d86972325d5d5d/8ff5a/feedback-2.png 240w", "/static/1a0030b498e7b52316d86972325d5d5d/e85cb/feedback-2.png 480w", "/static/1a0030b498e7b52316d86972325d5d5d/d9199/feedback-2.png 960w", "/static/1a0030b498e7b52316d86972325d5d5d/07a9c/feedback-2.png 1440w", "/static/1a0030b498e7b52316d86972325d5d5d/29114/feedback-2.png 1920w", "/static/1a0030b498e7b52316d86972325d5d5d/7f7f8/feedback-2.png 2396w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section section="Next" mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
              phone: 1
            }} mdxType="Col">
        <h5>💡 Takeaways and Next Steps</h5>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Embrace Ambiguity:</strong> Moving forward without knowing all the answers. Always be conciously curious and proceed forward with the best intentions. Keep thinking and pushing new ideas.</p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Build incrementally:</strong> Rather than large sweeping changes to the product, I seemed to experience less change aversion when introducing features incrementally in small bits.</p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Moving to a Slack app:</strong> Instead of browsing through a web app, could we let users query using a command directly in Slack?<br /><small>(It's on the roadmap 😎)</small></p>
        </Box>
        <Box bg={({
                theme
              }) => theme.grey200} color={({
                theme
              }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                  marginBottom: '0'
                }}><strong>Letting users filter:</strong> How may users filter things categorically in the interface? For example, if a user only wanted to search for dark themes.<br /><small>(This is on the roadmap too 💯)</small></p>
        </Box>
        <ProjectBox image="slack-themes-example-icon" title="Slack Themes" description="Choose and copy a theme to personalize a Slack workspace." link="https://slack-themes.now.sh" mdxType="ProjectBox" />
      </Col>
      <Col col={1 / 8} media={{
              phone: 1
            }} mdxType="Col"></Col>
      </>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABCcAAAQnAEmzTo0AAAC10lEQVQ4y21TuW5TQRTNH5Ao2M9+fsu8Zfw22+95C06iyAERIAVLAlGIEGKpKBFbgUSBqEBCCCRQoIYOARUSioSgoqGioIBf4Bvu4c4kVhxCcTQzd+6ce+4yEzXbwzhMS+i1XLEgowy2E+h9uWrDtVxIkcAwxR5fBo3WiTGjhuX4OFg2MTs3xLffv/D4xTNImSJKUty5eBOP3n1AfqgNw7DZ19tDyFw0MYo0IlaEJcNEf7CAn18/4/HD+8iKPnp5F6ev38HTt3dxqJ2iVHEw9vb/CtW+WnM1qcEpevUUNSfUKRtVh+0OQuGjUhOwXV/7KSiiHZ5dhUmjQLs3QKc/QJLlCJjMD2MEMkHItVRQNo/TD+oJPHVXT7chk92URwpjJkmbbY160oSMGxr/7hVxg9MvOjP6HKUtdbefMFSKWI1StR19e69WDZloNSKIdNBW0dM2NQn8dn/Kjgh1qknWQqaUNnLEHF1B2RTitImYVdVZ7eicNQuuZ7CfUBXX9aSG8CU8RhDW9SMVSBErItcLNYRfh8e+Pq+24+8SmqrDqvWK2PaZ3NODO2kITBnKyYfDHbUdb3xM9DRMcecPMMo7Y7OnhlXTQrViYLpUwbAb4f6lAW6tcbc9gemy4NFRJDs/hGEzLjVT3MgzLAYBHeQ7a5Ry1bQhEh6XuXWIfBlZFGH95CKOH+miN3MMt+990Si6SyiVDPbnmrOQF8eG2Dq7jI28RZOs1NaETkCGYVBj4QKdvvGeZlcfkFmTZFkZTU+7NDN/hl6++UOvXv+huYVVEnFMMu+Q44V0LkvoWien+VBSaVyhyQpdVsikCIoTCP0mjvZPYrYxhOtItOIeGrIL4UVYWF3B4fPrCPkj1GSBatRFVcTcB1eVgiZUyw9MlenylSu09ekjbW4+pzDMqN2aJSnbdHxQ0PeHa/TjyQYtz3fI8jKKk4JUZsOlFTq1epWKRp8M01aNob+p+McC+m9WnAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Coming soon for Slack Themes",
          "title": "Coming soon for Slack Themes",
          "src": "/static/d9696af394fcbdee62836fbe670b92f1/d9199/preview-2.png",
          "srcSet": ["/static/d9696af394fcbdee62836fbe670b92f1/8ff5a/preview-2.png 240w", "/static/d9696af394fcbdee62836fbe670b92f1/e85cb/preview-2.png 480w", "/static/d9696af394fcbdee62836fbe670b92f1/d9199/preview-2.png 960w", "/static/d9696af394fcbdee62836fbe670b92f1/c1b63/preview-2.png 1200w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      